export const APP_NAME = "SML ISUZU";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";

export const GOOGLE_MAP_KEY = 'AIzaSyBdimTWvq13sSAwcN-q1bQwBJf5kSJT85M';
export const LEAFLET_MAP_UI = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'

export const TABLE_ACTION_KEYS = {
  EDIT: "Edit",
  DELETE: "Delete",
  ADD: "Add",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  NAVIGATE: "Navigate",
  DOWNLOAD: "Download",
  VIEW: "View",
  IMPORT: "Import",
  EXPORT: "Export",
  CHANGE_PASSWORD: "ChangePassword",
  STATUS: "Status",
  TRIP_STATUS: "Trip-Status",
  INCIDENSE: "Raise_Incidence",
  COMPLAINT: "Raise-Complaint",
  BILLS: "Bills/Invoice",
  FILTER: "Filter",
  ACTIVITY_LOGS: "Activity-Logs",
  REFRESH: "Refresh/New",
  SHOW_POD: "Show-POD",
  START_POD: "Start-POD",
  ADD_DRIVER: "Add-Driver",
  VIEW_DETAILS: "View-Details",
};

export const ACTIVE_INACTIVE_STATUS = [
  {
    label: "Active",
    identifier: 1,
    value: 1,
    key: TABLE_ACTION_KEYS.STATUS,
  },
  {
    label: "In Active",
    identifier: 2,
    value: 2,
    key: TABLE_ACTION_KEYS.STATUS,
  },
];

export const TRIP_STATUS_FILTER = [
  { label: "CREATED", value: 1 },
  { label: "HANDED_OVER_TRANSPORTER", value: 2 },
  { label: "HANDED_OVER_DRIVER", value: 3 },
  { label: "GATE_OUT_NO_LOCATION", value: 4 },
  { label: "IN_TRANSIT", value: 5 },
  { label: "NEARBY_DESTINATION", value: 17 },
  { label: "VEHICLE_REACHED_DESTINATION", value: 6 },
  { label: "IN_POD", value: 7 },
  { label: "POD_RECEIVED_BY_TRANSPORTER", value: 8 },
  { label: "POD_APPROVED_TRANSPORTER", value: 9 },
  { label: "POD_APPROVED_SMLI", value: 10 },
  { label: "BILL_RAISED", value: 11 },
  { label: "TRIP_CLEARED", value: 12 },
  { label: "HALT_BY_BREAKDOWN", value: 13 },
  { label: "HALT_BY_ACCIDENT", value: 14 },
  { label: "HALT_BY_TRAFFIC_JAM", value: 15 },
  { label: "TRIP_CANCELLED", value: 16 }
];

export const TRIPS_STATUS = {
  1: "CREATED",
  2: "HANDED_OVER_TRANSPORTER",
  3: "HANDED_OVER_DRIVER",
  4: "GATE_OUT_NO_LOCATION",
  5: "IN_TRANSIT",
  6: "VEHICLE_REACHED_DESTINATION",
  7: "IN_POD",
  8: "POD_RECEIVED_BY_TRANSPORTER",
  9: "POD_APPROVED_TRANSPORTER",
  10: "POD_APPROVED_SMLI",
  11: "BILL_RAISED",
  12: "TRIP_CLEARED",
  13: "HALT_BY_BREAKDOWN",
  14: "HALT_BY_ACCIDENT",
  15: "HALT_BY_TRAFFIC_JAM",
  16: "TRIP_CANCELLED",
  17: "NEARBY_DESTINATION",
};

export const DATE_TIME_FORMAT = "MMM DD, YYYY hh:mm a";
export const DATE_FORMAT = "MMM DD, YYYY";

export const TOAST_TIMING = 3000;
export const DELETE_TOAST = "Deleted successfully";
export const UPDATE_TOAST = "Updated successfully";
export const ADDED_TOAST = "Added successfully";
