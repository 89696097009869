//foreground config and message listner
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAA085Zbo92JFePF23KxIji05u5WouCObU",
  authDomain: "sml-logistics.firebaseapp.com",
  projectId: "sml-logistics",
  storageBucket: "sml-logistics.appspot.com",
  messagingSenderId: "91903495191",
  appId: "1:91903495191:web:56a77e71a9d67361eba052",
  measurementId: "G-FLR27V5D0L",
};
initializeApp(firebaseConfig);
export const messaging = getMessaging();

export const requestPermission = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("permission granted");

      return getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAP_ID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
          } else {
            console.log("Failed to generated registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive token=>",
            err
          );
        });
    } else {
      console.log("permission not granted");
    }
  });
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
