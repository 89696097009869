import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY, TOAST_TIMING } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { Notification, toast } from "components/ui";
import { postApi } from "services/CommonService";
import { COMMON_APIS } from "constants/api.constant";
import { getAPIAccToUserType } from "utils/utils";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const {
    session: { token, signedIn },
  } = useSelector((state) => state.auth);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp?.data) {
        const { accessToken, userType } = resp?.data;
        const user = { ...resp.data, authority: [userType] };
        dispatch(setUser(user));
        dispatch(onSignInSuccess(accessToken));
        return {
          status: "success",
          message: "",
        };
      } else {
        return {
          status: "failed",
          message: "Something went wrong!",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message:
          errors?.response?.data?.message || errors?.toString() || "Error",
      };
    }
  };

  const verifyOtp = async (values) => {
    try {
      const result = await postApi(COMMON_APIS.VERIFY_OTP, values);
      if (result?.statusCode === 200) {
        const { accessToken, userType } = result?.data;
        dispatch(onSignInSuccess(accessToken));
        dispatch(
          setUser({
            ...result?.data,
            authority: [userType],
          })
        );
        return {
          status: "success",
          message: "",
        };
      } else {
        return {
          status: "failed",
          message: "Something went wrong!",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message:
          errors?.response?.data?.message || errors?.toString() || "Error",
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    localStorage.clear();
  };

  const signOut = async () => {
    const deviceId = localStorage.getItem("device_id");

    postApi(getAPIAccToUserType(COMMON_APIS.LOGOUT), { deviceId }).then(() => {
      handleSignOut();
      toast.push(
        <Notification closable type="success" duration={TOAST_TIMING}>
          Logout successfully
        </Notification>
      );
    });
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    verifyOtp,
  };
}

export default useAuth;
